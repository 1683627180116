import { useEffect, useRef, useState } from 'react';
import CardAuth from '.';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import classNames from 'classnames';

import Button from '../Button';
import Checkbox from '../Checkbox';
import ImageC from '../Image';
import Input from '../Input';

import classes from './index.module.less';

interface Props {
	onClose?: () => void;
	onSuccess?: () => void;
	updateLoadingState?: (val: boolean) => void;
}

const CardRecoverWhatsapp = ({
	onClose,
	// onSuccess,
	updateLoadingState,
}: Props) => {
	const isLoading = false;
	const otpNumberOfDigits = 6;
	const otpExpiryTime = parseInt(process.env.NEXT_PUBLIC_OTP_EXPIRED_DURATION as string); // in seconds

	const [step, setStep] = useState<number>(1);
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
	const [clipboardText, setClipboardText] = useState<string>('');
	const [formData, setFormData] = useState<{
		code: string;
		number: string;
	}>({
		code: '+62',
		number: '',
	});
	const [otp, setOtp] = useState(new Array(otpNumberOfDigits).fill(''));
	const [otpExpiredCountdown, setOtpExpiredCountdown] = useState<number>(0);

	const [checked, setChecked] = useState<boolean>(false);

	const finalOtp = otp.join('');

	const otpBoxRef = useRef<any[]>([]);
	const otpExpiredCountdownRef = useRef(otpExpiryTime);

	let otpExpiredCountdownInterval: any = null;
	const stopOtpExpiredCountdown = () => {
		if (otpExpiredCountdownInterval !== null) {
			clearInterval(otpExpiredCountdownInterval);
			otpExpiredCountdownInterval = null;
		}
	};
	const startOtpExpiredCountdown = () => {
		stopOtpExpiredCountdown();

		otpExpiredCountdownRef.current = otpExpiryTime;
		setOtpExpiredCountdown(otpExpiredCountdownRef.current);

		otpExpiredCountdownInterval = setInterval(() => {
			if (otpExpiredCountdownRef.current === 0) {
				stopOtpExpiredCountdown();
				return;
			}
			otpExpiredCountdownRef.current = otpExpiredCountdownRef.current - 1;
			setOtpExpiredCountdown(otpExpiredCountdownRef.current);
		}, 1000);
	};

	const getCountdownTimer = () => {
		const min: string = Math.floor(otpExpiredCountdown / 60).toString();
		let sec: string = (otpExpiredCountdown % 60).toString();
		if (sec.length === 1) {
			sec = '0' + sec;
		}
		return min + ':' + sec;
	};

	const getClipboardText = async () => {
		let text = (await navigator.clipboard.readText()) ?? '';
		text = text.replace(/\s/g, '');
		setClipboardText(text);
	};

	let getClipboardTextInterval: any = null;
	const startGetClipboardTextInterval = () => {
		getClipboardText();

		if (getClipboardTextInterval === null) {
			getClipboardTextInterval = setInterval(() => {
				getClipboardText();
			}, 1000);
		}
	};
	const stopGetClipboardTextInterval = () => {
		clearInterval(getClipboardTextInterval);
		getClipboardTextInterval = null;
	};

	const validateFormData = (data: any) => {
		if (
			data == null ||
			Object.values(data ?? [])?.find((val: any) => val.length < 3) !== undefined
		) {
			return false;
		}

		return true;
	};

	const handleFormInputChange = (e: any) => {
		const name = e.target.name;
		let value = e.target.value;
		// if (name === 'username') {
		// value = value.replace(/[^0-9a-z]/gi, '');
		// }
		console.log(value);
		if (name === 'code') {
			if (value.length > 5) {
				return;
			} else {
				// Removing every non-digit character except a plus (+) sign
				value = value.replace(/[^\d\+]+/g, '').replace(/(.)\++/g, '$1');
			}
		} else if (name === 'number') {
			if (value.length > 15) {
				return;
			}
		}
		setFormData({ ...formData, [name]: value });
	};

	const handleOtpChange = (value: number, index: number) => {
		let newArr = [...otp];
		newArr[index] = value;
		setOtp(newArr);

		if (value && index < otpNumberOfDigits - 1) {
			otpBoxRef.current[index + 1].focus();
		}
	};

	const handleOtpBackspaceAndEnter = (e: any, index: number) => {
		if (e.key === 'Backspace' && !e.target.value && index > 0) {
			otpBoxRef.current[index - 1].focus();
		}
		if (e.key === 'Enter' && e.target.value && index < otpNumberOfDigits - 1) {
			otpBoxRef.current[index + 1].focus();
		}
	};

	const handlePasteFromClickboardClick = () => {
		setOtp([
			clipboardText[0] ?? '',
			clipboardText[1] ?? '',
			clipboardText[2] ?? '',
			clipboardText[3] ?? '',
			clipboardText[4] ?? '',
			clipboardText[5] ?? '',
		]);
	};

	const handleConnectClick = (e: any) => {
		e.preventDefault();

		startOtpExpiredCountdown();
		setStep(2);

		// if (!validatePassword()) {
		// return notify('Use 8 or more characters mixing letters and numbers', 'error');
		// } else if (!validateRePassword()) {
		// return notify('Please re-enter password correctly', 'error');
		// }

		// registerEmail(
		// {
		// code: formData.code,
		// username: formData.username,
		// password: formData.password,
		// password_confirmation: formData.password_confirmation,
		// },
		// {
		// onSuccess: (res: any) => {
		// if (res.status === 200) {
		// startOtpExpiredCountdown();
		// setStep(2);
		// } else {
		// notify(res?.data?.message ?? 'Failed to register', 'error');
		// }
		// },
		// },
		// );
	};

	const handleResendOtpClick = () => {
		// resendOtp(
		// { email: formData.email },
		// {
		// onSuccess: (res: any) => {
		// if (res.status === 200) {
		// startOtpExpiredCountdown();
		// } else {
		// notify(res?.data?.message ?? 'Failed to resend otp', 'error');
		// }
		// },
		// },
		// );
	};

	const handleVerifyOtpClick = (e: any) => {
		e.preventDefault();

		if (finalOtp.length === otpNumberOfDigits) {
			// verifyOtp(
			// { email: formData.email, otp: finalOtp },
			// {
			// onSuccess: (res: any) => {
			// if (res.status === 200) {
			// notify('Account created. You can now login with your new account.');
			// onSuccess?.();
			// } else {
			// notify(res?.data?.message ?? 'Failed to verify otp', 'error');
			// }
			// },
			// },
			// );

			setStep(3);
		}
	};

	const handleSubmitRecoveryKey = () => {
		setStep(4);
	};

	useEffect(() => {
		updateLoadingState?.(isLoading);
	}, [isLoading]);

	useEffect(() => {
		startGetClipboardTextInterval();
		return () => {
			stopGetClipboardTextInterval();
		};
	}, []);

	useEffect(() => {
		setButtonDisabled(true);
		setOtp(new Array(otpNumberOfDigits).fill(''));
	}, [step]);

	useEffect(() => {
		if (validateFormData(formData)) {
			setButtonDisabled(false);
		} else {
			setButtonDisabled(true);
		}
	}, [formData]);

	useEffect(() => {
		if (finalOtp.length === otpNumberOfDigits) {
			setButtonDisabled(false);
		} else {
			setButtonDisabled(true);
		}
	}, [finalOtp]);

	return (
		<CardAuth showCreoplayImage={false}>
			<div className={classes.header}>
				<div>
					{step > 1 && step < 3 && (
						<ImageC
							alt="icon back"
							className="cursor-pointer"
							src="/icons/icon-back-white.svg"
							width={16}
							height={16}
							onClick={() => setStep(step - 1)}
						/>
					)}
				</div>
				<div>
					<ImageC
						alt="icon close"
						className="cursor-pointer"
						src="/icons/icon-close-white.svg"
						width={16}
						height={16}
						onClick={onClose}
					/>
				</div>
			</div>

			{step !== 4 && (
				<h1 className={classes.title}>
					<ImageC src="/icons/whatsapp-green.png" width={30} height={30} />
					Recover Account
				</h1>
			)}
			{step === 4 && <h1 className={classes.title}>Your Account Has been Recovered</h1>}

			{step === 1 && (
				<>
					<form
						className={classNames(classes.form, 'pt-4 mb-0')}
						// onSubmit={handleConnectClick}
					>
						<Row gutter={[30, 12]}>
							<Col span={24}>
								<div className={classes['phone-number']}>
									<div
										className={classes.code}
										style={{
											width: `calc(${formData?.code?.toString()?.length ?? 0}ch + 38px)`,
										}}
									>
										<Input
											name="code"
											placeholder="+62"
											// defaultValue={'+62'}
											value={formData.code}
											onChange={handleFormInputChange}
										/>
									</div>
									<div className={classes.line}></div>
									<div className={classes.number}>
										<Input
											className={classes.number}
											type="number"
											name="number"
											placeholder=""
											value={formData.number}
											onChange={handleFormInputChange}
										/>
									</div>
								</div>
							</Col>
						</Row>
					</form>
					<div className={classNames(classes['create-account'], 'mt-4 text-center')}>
						Enter your Whatsapp number above and we will send you the verification number
					</div>
					<Button
						className={classNames(classes['submit-btn'], 'w-100 mt-auto mb-4')}
						// type="submit"
						// loading={isLoadingVerifyOtp}
						// disabled={buttonDisabled}
						onClick={handleConnectClick}
					>
						Send verification number
					</Button>
					<div className={classes.agreement}>
						By signing up you are agreeing to <br />
						our <a>Terms of Use</a> and our <a>Privacy Policy</a>
					</div>
				</>
			)}

			{step === 2 && (
				<form
					className={classNames(classes.form, classes['form-otp'], 'pt-4')}
					onSubmit={handleVerifyOtpClick}
				>
					<div className={classes['row-input-otp']}>
						{otp.map((digit, index) => (
							<div key={index} className={classes['col-input-otp']}>
								<div className={classes['wrapper-input']}>
									<input
										className={classes['input-otp']}
										ref={(reference: any) => (otpBoxRef.current[index] = reference)}
										value={digit}
										min={0}
										max={9}
										maxLength={1}
										onChange={(e: any) => handleOtpChange(e.target.value, index)}
										onKeyUp={(e: any) => handleOtpBackspaceAndEnter(e, index)}
									/>
								</div>
							</div>
						))}
					</div>
					<div className={classes.label}>
						<div>
							Enter the verification code that has been sent too{' '}
							<span className="fw-500">{formData.code + formData.number}</span>
						</div>
					</div>
					{finalOtp.length === otpNumberOfDigits ? (
						<Button
							className={classNames('w-100', classes['submit-btn'])}
							type="submit"
							loading={isLoading}
							disabled={buttonDisabled}
						>
							Continue
						</Button>
					) : (
						<Button
							className={classNames('w-100', classes['submit-btn'])}
							onClick={handlePasteFromClickboardClick}
							disabled={clipboardText.length === 0}
						>
							Paste from Clipboard
						</Button>
					)}

					<div className={classNames(classes.label, 'fw-400')}>
						<div>
							Expires in <span className="fw-500">{getCountdownTimer()}</span>
						</div>
						<a
							className={classNames({ disabled: otpExpiredCountdown !== 0 })}
							onClick={handleResendOtpClick}
						>
							Resend code
						</a>
					</div>
				</form>
			)}

			{step === 3 && (
				<>
					<div className={classNames('d-flex flex-column align-items-center mt-5 pt-2')}>
						<ImageC src="/icons/icon-recovery-key.svg" width={88} height={88} />
						<span className="fs-12 fw-400 mt-4 mb-3 opacity-80">
							Please input your Recovery Key
						</span>
						<Input
							className="w-100 mt-3 mb-5"
							name="recoveryKey"
							placeholder="Input recovery key"
						/>
						<Button
							className={classNames('w-100 mt-4', classes['submit-btn'])}
							// disabled={!checked}
							onClick={handleSubmitRecoveryKey}
						>
							Continue
						</Button>
					</div>
				</>
			)}

			{step === 4 && (
				<>
					<div className={classNames('w-100 d-flex flex-column align-items-center mt-5 pt-2')}>
						<span className="fs-12 fw-400 mt-4 mb-3 opacity-80">
							This is your new Recovery Code for your new number. Please keep this Recovery Code
							below!
						</span>
						<div className={classNames(classes['recovery-key'], 'mb-5')}>ASdasd0wb3hos8cvasfb</div>
						<Checkbox
							size={16}
							label={
								<span className="fs-12 fw-400 opacity-80">Yes I have saved the Recovery Code</span>
							}
							checkedIcon="check"
							checked={checked}
							onChange={(e) => setChecked(e.target.checked)}
						/>
						<Button
							className={classNames('w-100 mt-4', classes['submit-btn'])}
							type="submit"
							disabled={!checked}
						>
							Enter the Creoplay
						</Button>
					</div>
				</>
			)}
		</CardAuth>
	);
};

export default CardRecoverWhatsapp;
